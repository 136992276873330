import axios from "axios";
import { store, history } from "../redux/store";
import { signinSuccess, signoutSuccess } from "../redux/actions/auth";
import appActions from "../redux/actions/app";
import { Auth } from 'aws-amplify';

const { REACT_APP_API_URL } = process.env;

const { setAppError } = appActions

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
  withCredentials: true
});

axiosInstance.interceptors.request.use(config => {
  const authState = store.getState().auth;

  if (authState.isAuthenticated) {

    // Check if the session has expired
    if (authState.exp < Math.round(Date.now() / 1000)) {
      // If the sesion has expired dispatch signoutSuccess
      store.dispatch(signoutSuccess());
    }
    else {
      // If the sesion has not expired refresh it and dispatch signinSuccess

      // First I need to get the current user and refresh the session
      Auth.currentAuthenticatedUser()
        .then(user => {
          user.refreshSession(user.signInUserSession.refreshToken, (e, s) => { });
        });
      // Then I need to get the refreshed user again and store ir
      Auth.currentAuthenticatedUser()
        .then(user => {
          store.dispatch(signinSuccess(user));
        });

      // Add the authorization header only if the session is active and the user is authenticated
      config.headers.Authorization = `Bearer ${authState.idToken}`;
    }

  }

  return config;
}, err => {
  return Promise.reject(err);
})

// Intercept 401 and log user out
axiosInstance.interceptors.response.use(null, error => {
  if (error) {
    if (error.response) {
      const code = error.response.status;
      const genericServerErrorMsg = `An error occurred processing your request. Please try again later.`;

      var ApiErrorMsg = error.response.data;
      //Special check for response with file methods
      if (error.response.request.responseType === 'arraybuffer'
        && error.response.data.toString() === '[object ArrayBuffer]'
      ) {
        ApiErrorMsg = Buffer.from(error.response.data).toString('utf8');
      }

      switch (code) {
        // 4xx: Client Errors
        // Unauthorized
        case 401:
          store.dispatch(signoutSuccess());
          break;

        // Forbidden      
        case 403:
          store.dispatch(setAppError(`You are not allowed to perform this action.`));
          break;

        // Not found       
        case 404:
          history.push('/notfound');
          break;

        // Conflict       
        case 409:
          break;

        // Other
        case 400:
        case (code > 400 && code < 500):
          store.dispatch(setAppError(ApiErrorMsg ? ApiErrorMsg : genericServerErrorMsg, 5))
          break;

        // 5xx: Server Errors
        // Service Unavaiable
        case 503:
          history.push('/error');
          break;

        case 504:
          store.dispatch(setAppError(`Your request timed out. Please try again.`))
          break;

        // Other
        case 500:
        case code > 500:
          store.dispatch(setAppError(genericServerErrorMsg))
          break;

        default:
          store.dispatch(setAppError(genericServerErrorMsg));
          break;
      }
    }
    else {
      // Non-network error
      history.push('/error')
    }

    //Cleans up app global error.
    store.dispatch(setAppError(null))
  }


  return Promise.reject(error);
});

export default axiosInstance;
