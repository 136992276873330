import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "../containers/App/App";
import asyncComponent from "../helpers/AsyncFunc";
import signin from '../views/Signin/Signin'

const RestrictedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isAuthenticated }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path={"/"}
          component={signin}
        />
        <Route
          exact
          path={"/signin"}
          component={signin}
        />
        <Route
          exact
          path={"/forgotpassword"}
          component={asyncComponent(() => import("../views/ForgotPassword/ForgotPassword"))}
        />
        <Route
          exact
          path={"/mfasetup"}
          component={asyncComponent(() => import("../views/MFASetup/MFASetup"))}
        />
        <Route
          exact
          path={"/error"}
          component={asyncComponent(() => import("../views/NotFound/Error"))}
        />
        <RestrictedRoute path="/app" component={App} isAuthenticated={isAuthenticated} />
        <Route
          component={asyncComponent(() => import("../views/NotFound/NotFound"))}
        />
      </Switch>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isAuthenticated: state.auth.isAuthenticated
}))(PublicRoutes);
