import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import auth from "./auth";
import app from "./app";
import user from "./user";

export default combineReducers({
  auth,
  app,
  user,
  router: routerReducer
});
