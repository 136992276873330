import * as roles from "../../catalogs/roles";

/*
EXAMPLE OF SECTION AVAILABLE ONLY FOR SPECIFIC USERS
{
    key: "BIDashboards/Sarona",
    label: "Loan Portfolio Dashboard",
    users: ["sarona@sarona.com"], //User specific items must should not include 'roles' property
    leftIcon: "fas fa-tachometer-alt"
  },
*/

/*
IMPORTANT - HOW TO CONFIGURE
Sidebar menu options must contain key, label, leftIcon and roles. users is optional.
Roles should contain one or more of the allowed roles.
Users could contain one or more emails. If users value is set, only the specified users will see the option in the menue.
*/

const options = [
  {
    key: "home",
    label: "Home",
    roles: [roles.ACCIAL, roles.COINVESTOR, roles.FACILITY, roles.INVESTOR, roles.PARTNER, roles.PARTNERADMIN, roles.TRANSACTIONADMIN, roles.ORCAAASLPA, roles.ORCAAASDFA, roles.FUNDADMIN],
    leftIcon: "fas fa-home"
  },
  {
    key: "BIDashboards/DataOverview",
    label: "Data Overview",
    roles: [roles.ACCIAL],
    leftIcon: "fas fa-tachometer-alt"
  },
  {
    key: "BIDashboards/Investment",
    label: "Debt Facility Analysis",
    roles: [roles.ACCIAL, roles.COINVESTOR, roles.TRANSACTIONADMIN, roles.PARTNER, roles.PARTNERADMIN, roles.ORCAAASDFA],
    leftIcon: "fas fa-tachometer-alt"
  },
  {
    key: "BIDashboards/Fund",
    label: "Fund Dashboard",
    roles: [roles.ACCIAL],
    leftIcon: "fas fa-tachometer-alt"
  },
  {
    key: "BIDashboards/Fund",
    label: "Fund Dashboard",
    roles: [roles.COINVESTOR],
    users: ["auribem@skandia.com.co"],
    leftIcon: "fas fa-tachometer-alt"
  },
  {
    key: "BIDashboards/RiskAnalysis",
    label: "Loan Portfolio Analysis",
    roles: [roles.ACCIAL],
    leftIcon: "fas fa-tachometer-alt"
  },
  {
    key: "BIDashboards/RiskAnalysisArchive",
    label: "Loan Portfolio Analysis",
    roles: [roles.ORCAAASLPA],
    leftIcon: "fas fa-tachometer-alt"
  },
  {
    key: "BIDashboards/PortfolioReport",
    label: "Portfolio Report",
    roles: [roles.ACCIAL],
    leftIcon: "fas fa-tachometer-alt"
  },
  // {
  //   key: "BIDashboards/Playground",
  //   label: "Report Sandbox",
  //   roles: [roles.ACCIAL],
  //   leftIcon: "fas fa-tachometer-alt"
  // },
  {
    key: "AdvanceRequests/AdvanceRequests",
    label: "Advance Requests",
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.COINVESTOR, roles.TRANSACTIONADMIN],
    leftIcon: "fas fa-table",
  },
  {
    key: "Invoices/Invoices",
    label: "Invoices",
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.COINVESTOR, roles.TRANSACTIONADMIN],
    leftIcon: "fas fa-table",
  },
  {
    key: "JobTrigger/JobTrigger",
    label: "Job Trigger",
    roles: [roles.ACCIAL],
    leftIcon: "fas fa-table",
  },
  {
    key: "LoanSchedules/LoanSchedules",
    label: "Loan Schedule",
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.COINVESTOR, roles.TRANSACTIONADMIN, roles.FUNDADMIN],
    leftIcon: "fas fa-table",
  },
  {
    key: "Waivers/Waivers",
    label: "Waivers",
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.COINVESTOR],
    leftIcon: "fas fa-table",
  },
  // {
  //   key: "LoansOverview/Loans",
  //   label: "Loans Overview",
  //   roles: [roles.ACCIAL],
  //   leftIcon: "fas fa-table",
  // },  
  {
    key: "pricer",
    label: "Pricer",
    roles: [roles.ACCIAL],
    leftIcon: "fas fa-users-cog",
    children: [
      {
        key: "Pricer/YieldCoverageRatio",
        label: "Yield Coverage Ratio"
      }
    ]
  },
  {
    key: "backOffice",
    label: "Back Office",
    roles: [roles.ACCIAL],
    leftIcon: "fas fa-users-cog",
    children: [
      {
        key: "BackOffice/ApprovalRequest",
        label: "Approval Request"
      },
      {
        key: "BackOffice/AccountMovements",
        label: "Bank Account Movements"
      },
      {
        key: "BackOffice/Accounts",
        label: "Bank Account Statements"
      },
      {
        key: "BackOffice/Covenants",
        label: "Covenants"
      },
      {
        key: "BackOffice/CovenantWaivers",
        label: "Covenant Waivers",
      },
      {
        key: "BackOffice/Datasets",
        label: "Datasets"
      },
      {
        key: "BackOffice/Entities",
        label: "Entities"
      },
      {
        key: "BackOffice/Facilities",
        label: "Facilities"
      },
      {
        key: "BackOffice/FacilityDocuments",
        label: "Facility Reporting"
      },
      {
        key: "BackOffice/FundAccounts",
        label: "Fund Account Statements"
      },
      {
        key: "BackOffice/FundLenders",
        label: "Fund Lenders"
      },
      {
        key: "BackOffice/FundMarkToMarketHedge",
        label: "Fund Mark To Market"
      },
      {
        key: "BackOffice/Funds",
        label: "Funds"
      },
      {
        key: "BackOffice/FacilityMarkToMarketHedge",
        label: "Mark To Market Hedge"
      },
      {
        key: "BackOffice/PartnerDocuments",
        label: "Partner Reporting"
      },
      {
        key: "BackOffice/Partners",
        label: "Partners"
      },
      {
        key: "BackOffice/PledgedLoans",
        label: "Pledged Loans"
      },
      {
        key: "BackOffice/TransactionUpdates",
        label: "Transaction Updates"
      },
    ]
  },
  {
    key: "archive",
    label: "Archive",
    roles: [roles.ACCIAL],
    leftIcon: "fas fa-archive",
    children: [
      {
        key: "BIDashboards/RiskAnalysisArchive",
        label: "Loan Portfolio Analysis"      
      }
    ]
  },
  {
    key: "trnasAdminBackOffice",
    label: "Back Office",
    roles: [roles.TRANSACTIONADMIN],
    leftIcon: "fas fa-users-cog",
    children: [
      {
        key: "BackOffice/Accounts",
        label: "Accounts"
      },
      {
        key: "BackOffice/AccountMovements",
        label: "Account Movements"
      },
      {
        key: "BackOffice/FacilityDocuments",
        label: "Facility Reporting"
      },
      {
        key: "BackOffice/FacilityMarkToMarketHedge",
        label: "Mark To Market Hedge"
      },
      {
        key: "BackOffice/PartnerDocuments",
        label: "Partner Reporting"
      },
      {
        key: "BackOffice/PledgedLoans",
        label: "Pledged Loans"
      },
    ]
  },
  {
    key: "partnerBackOffice",
    label: "Back Office",
    roles: [roles.PARTNER, roles.PARTNERADMIN],
    leftIcon: "fas fa-users-cog",
    children: [
      {
        key: "BackOffice/AccountMovements",
        label: "Bank Account Movements"
      },
      {
        key: "BackOffice/Accounts",
        label: "Bank Account Statements"
      },
      {
        key: "BackOffice/FacilityDocuments",
        label: "Facility Reporting"
      },
      {
        key: "BackOffice/PartnerDocuments",
        label: "Partner Reporting"
      }
    ]
  },
  {
    key: "coinvestorBackOffice",
    label: "Back Office",
    roles: [roles.COINVESTOR],
    leftIcon: "fas fa-users-cog",
    children: [
      {
        key: "BackOffice/FacilityDocuments",
        label: "Facility Reporting"
      },
      {
        key: "BackOffice/PartnerDocuments",
        label: "Partner Reporting"
      }
    ]
  },
  {
    key: "manage",
    label: "Manage",
    roles: [roles.ADMINISTRATOR],
    leftIcon: "fas fa-tools",
    children: [
      {
        key: "Signup/Signup",
        label: "Create User"
      }
    ]
  }
];
export default options;
