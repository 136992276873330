import React, { useCallback, useEffect, useState } from "react";
import { Popover } from 'antd';
import { connect } from 'react-redux';
import notificationsApi from "../../api/notifications";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { Link } from 'react-router-dom';

const TopbarNotification = (props) => {
  const { userNotifications } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    notificationsApi.refreshUserNotifications();
    /*   getNotifications();
      //runs every 5 minutes 300000
      const interval = setInterval(() => {
        getNotifications()
      }, 300000);
      return () => clearInterval(interval);
  
    }, [getNotifications]);
  
    const getNotifications = useCallback(async () => {
      try {
        await notificationsApi.refreshUserNotifications();
      } catch (error) {
        console.log(error);
      } */
  }, [])

  const hide = () => {
    setVisible(false);
  }

  const handleVisibleChange = () => {
    setVisible(!visible);
  }

  const content = (
    <TopbarDropdownWrapper className="topbarNotification">
      <div className="isoDropdownHeader">
        <h3>
          <label>Notifications</label>
        </h3>
      </div>
      <div className="isoDropdownBody">
        {(Array.isArray(userNotifications) && userNotifications.length > 0) &&
          userNotifications.map(notification =>
            <a className="isoDropdownListItem" key={notification.id} href={`/app/${notification.url}`}>
              <h5>{notification.subject}</h5>
              <p>{notification.body}</p>
            </a>
          )
        }
      </div>
      <a className="isoViewAllBtn" href="/app/Notifications">
        <label>View All</label>
      </a>
    </TopbarDropdownWrapper>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      placement="bottomLeft"
    >
      <div className="isoIconWrapper">
        <i
          className="ion-android-notifications"
        />
        {(Array.isArray(userNotifications) && userNotifications.length > 0) &&
          <span style={{ backgroundColor: "Red" }} >{userNotifications.length}</span>
        }
      </div>
    </Popover>
  );
}

export default connect(state => ({ userNotifications: state.user.notifications }))(TopbarNotification); 