import React, { Component } from "react";
import Popover from "../../components/isomorphic/uielements/popover";
import userpic from "../../assets/accial-logo.png"
import { withRouter } from "react-router-dom";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { Auth } from 'aws-amplify';
import { store } from "../../redux/store";
import { userSettings } from '../../redux/actions/user'

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  signOut = () => {
    Auth.signOut()
      .then(() => {
        store.dispatch(userSettings({ teams: [], permissions: [], notifications: [] }))
        this.props.history.push('/');
      })
      .catch(err => {
        console.log('Error on sign out: ', err);
      });
  }

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <a className="isoDropdownLink" href="/app/changepassword">
          Change Password
        </a>
        <a className="isoDropdownLink" href="# " onClick={this.signOut}>
          Sign out
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={userpic} />
          {/*
          Green dot for status online
          <span className="userActivity online" /> 
          */}
        </div>
      </Popover>
    );
  }
}
export default withRouter(TopbarUser);
