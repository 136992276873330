import axios from "./axios";
import { store } from "../redux/store";
import { userNotifications } from '../redux/actions/user'

const notificationsApi = {
  getPendingApprovalRequest: async filters =>
    axios.post("/notifications/GetPendingApprovalRequest", filters),
  getAllUserNotifications: async filters =>
    axios.post("/notifications/GetUserNotifications", filters),
  refreshUserNotifications: async () =>
    axios.get("/notifications/GetUserNotifications")
      .then(response => {
        store.dispatch(userNotifications({ notifications: response.data }))
      })
};

export default notificationsApi;