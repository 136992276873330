import { createReducer } from "redux-starter-kit";

const initialState = {
  teams: [],
  permissions: [],
  notifications: []
};

const authReducer = createReducer(initialState, {
  USER_SETTINGS: (state, action) => ({
    teams: action.payload.teams,
    permissions: action.payload.permissions,
    notifications: action.payload.notifications
  }),
  USER_NOTIFICATIONS: (state, action) => ({
    teams: state.teams,
    permissions: state.permissions,
    notifications: action.payload.notifications
  })
});

export default authReducer;