import themedefault from "./themedefault";
import theme2 from "./theme2";
import accialTheme from "./accialTheme";

const themes = {
  themedefault,
  theme2,
  accialTheme
};
export default themes;
