import defaultTheme from "./themedefault";
import clone from "clone";

const theme = clone(defaultTheme);

theme.themeName = "accialTheme";

// Sidebar
theme.sidebar = {
  buttonColor: "#00769A",
  backgroundColor: "#00769A",
  textColor: "#ffffff"
};

// Sidebar
theme.topbar = {
  buttonColor: "#00769A",
  backgroundColor: "#ffffff",
  textColor: "#000000"
};

export default theme;
