import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, message } from "antd";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import appActions from "../../redux/actions/app";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import AppHolder from "./commonStyle";
import "./global.css";
import PrivateRouter from "../../routes/PrivateRouter";

const { Content } = Layout;
const { toggleAll, setAppError } = appActions;

export class App extends Component {
  render() {
    const { url } = this.props.match;
    const { height, appError } = this.props;
    const appHeight = window.innerHeight;
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="100" handler="onResize">
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            <Topbar url={url} />
            <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
              <Sidebar url={url} />
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: height,
                  overflowY: "hidden"
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    marginTop: 70,
                    height: "calc(100vh - 275px)",
                    overflowY: "auto",
                    flexShrink: "0",
                    background: "#f1f3f6",
                    position: "relative"
                  }}
                >
                  <div style={{ padding: 25, height: '100%' }}>
                    {/* displays global error message, dispatched from Axios.js */}
                    {appError && message.warning(appError, 4)}
                    <PrivateRouter
                      url={url}
                      pathname={this.props.location.pathname}
                    />
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    height: state.app.height,
    appError: state.app.appError
  }),
  {
    toggleAll,
    setAppError
  }
)(App);
