import axios from "./axios";

const usersApi = {
  getUserTeams: async () =>
    axios.get("/users/GetUserTeams").then(response => response.data),
  getUserPermissions: async () =>
    axios.get("/users/GetUserPermissions").then(response => response.data),
  sendNewUserMail: async filters =>
    axios.post("/users/SendNewUserMail", filters)
      .then(response => response.data),
  getNewUserPassword: () => {
    const numbers = '0123456789';
    const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const specialCharacters = "!+%&/()=?_#$;:*-@";

    let characterList = '';
    let password = '';

    for (let i = 0; i < 3; i++) {
      const characterIndex = Math.round(Math.random() * numbers.length)
      characterList = characterList + numbers.charAt(characterIndex)
    }

    for (let i = 0; i < 3; i++) {
      const characterIndex = Math.round(Math.random() * specialCharacters.length)
      characterList = characterList + specialCharacters.charAt(characterIndex)
    }

    for (let i = 0; i < 3; i++) {
      const characterIndex = Math.round(Math.random() * upperCaseLetters.length)
      characterList = characterList + upperCaseLetters.charAt(characterIndex)
    }

    for (let i = 0; i < 3; i++) {
      const characterIndex = Math.round(Math.random() * lowerCaseLetters.length)
      characterList = characterList + lowerCaseLetters.charAt(characterIndex)
    }

    password = characterList.split('').sort(function () { return 0.5 - Math.random() }).join('');

    return password;

  },
};

export default usersApi;
