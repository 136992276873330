export const ACCIAL_BLUE = "#00769A"
export const ACCIAL_DARK_BLUE = "#262A3C"

//Length must be 16 or higher
export const BLUE_RANGE_COLORS = ["#28415e",
                                "#2e4a6b",
                                "#345479",
                                "#3a5d87",
                                "#406794",
                                "#4670a2",
                                "#4c79b0",
                                "#5883b7",
                                "#668ebd",
                                "#7498c3",
                                "#81a2c9",
                                "#8faccf",
                                "#9db6d5",
                                "#aac0da",
                                "#b8cbe0",
                                "#c6d5e6"];

export const BOOLEAN_COLORS = ["red", "blue"];
export const FLAG_COLORS = ["red", "green"];
                                