import React from "react";
import { Provider } from "react-redux";
import { store, history, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import PublicRouter from "./routes/PublicRouter";

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <PublicRouter history={history} />
    </PersistGate>
  </Provider>
);

export default App;
