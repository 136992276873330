import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/accial-logo-horizontal-white.png";
import logoCollapsed from "../../../assets/accial-logo-no-text-white.png";

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/app/home">
              <img src={logoCollapsed} alt="Accial logo" width={50} />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/app/home">
            <img src={logo} alt="Accial logo" width={160} />
          </Link>
        </h3>
      )}
    </div>
  );
};
