export default {
  apiUrl: "http://yoursite.com/api/"
};

const siteConfig = {
  siteName: "ISOMORPHIC",
  siteIcon: "ion-flash",
  siteIconCollapsed: "ion-flash",
  footerText: "Isomorphic ©2018 Created by RedQ, Inc"
};
const themeConfig = {
  topbar: "accialTheme",
  sidebar: "accialTheme",
  layout: "accialTheme",
  theme: "accialTheme"
};
const language = "english";

const jwtConfig = {
  fetchUrl: "/api/",
  secretKey: "secretKey"
};

export { siteConfig, language, themeConfig, jwtConfig };
