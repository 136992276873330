import React, { useState, useEffect } from "react";
import { Input } from "antd";
import zxcvbn from 'zxcvbn';
import './NewPasswordInput.css';

const NewPasswordInput = ({ placeholder, onChange, value, onPassValidations }) => {
  const [password, setPassword] = useState(value);

  const [passwordLength, setPasswordLength] = useState(false);
  const [containsNumbers, setContainsNumbers] = useState(false);
  const [containsSymbols, setContainsSymbols] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);

  const [testResult, setTestResult] = useState("");
  const [number, setNumber] = useState("");

  useEffect(() => {
    checkPassAllValidations();
  }, [password]);

  useEffect(() => {
    setPassword(value);
    if (value) { handleChange(value); }
    else { handleChange(""); }
  }, [value]);

  /* Validations  */
  // check length
  function checkLength(string) {
    var matches = string.length >= 10 ? true : false
    setPasswordLength(matches)
  }

  // check to see if there is any number
  function checkForNumbers(string) {
    var matches = string.match(/\d+/g);
    setContainsNumbers(matches != null ? true : false)
  }

  // check for symbols
  function checkForSymbols(string) {
    var symbols = new RegExp(/[^A-Z a-z0-9]/);
    setContainsSymbols(symbols.test(string) ? true : false)
  }

  // check for upper case
  function checkForUpperCase(string) {
    var matches = string.match(/[A-Z]/);
    setIsUpperCase(matches != null ? true : false)
  }

  // check for lower case
  function checkForLowerCase(string) {
    var matches = string.match(/[a-z]/);
    setIsLowerCase(matches != null ? true : false)
  }

  // check for lower case
  function checkForStrength(string) {
    var result = zxcvbn(string);
    setTestResult(result.score);

    var score = ((result.score * 100) / 4);
    setNumber(score);
  }

  function checkPassAllValidations() {
    if (passwordLength == true &&
      containsNumbers == true &&
      containsSymbols == true &&
      isUpperCase == true &&
      isLowerCase == true &&
      testResult >= 3) {
      onPassValidations(false)
    }
    else {
      onPassValidations(true)
    }
  }
  /* Validations  */

  /* Style  */
  const funcProgressColor = () => {
    switch (testResult) {
      case 0:
        return '#828282';
      case 1:
        return '#EA1111';
      case 2:
        return '#FFAD00';
      case 3:
        return '#9bc158';
      case 4:
        return '#00b500';
      default:
        return '#828282';
    }
  }

  const createPassLabel = () => {
    switch (testResult) {
      case 0:
        return 'Very weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Poor';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Very weak';
    }
  }

  const changePasswordColor = () => ({
    width: `${number}%`,
    background: funcProgressColor(),
  })
  /* Style  */

  const handleChange = async (e) => {
    let targetValue = e.replace(/\s/g, '');

    checkForStrength(targetValue);
    checkLength(targetValue);
    checkForNumbers(targetValue);
    checkForSymbols(targetValue);
    checkForUpperCase(targetValue);
    checkForLowerCase(targetValue);

    setPassword(e);
    onChange(e);
  }

  return (
    <React.Fragment>
      <Input
        placeholder={placeholder}
        value={password}
        onChange={e => handleChange(e.target.value)}
        type="password"
      />
      <ul className='validation-list'>
        <li> <span className={'validation-item ' + (passwordLength ? 'green' : null)}>Require at least 10 characters</span></li>
        <li> <span className={'validation-item ' + (containsNumbers ? 'green' : null)}>Require at least one number</span></li>
        <li> <span className={'validation-item ' + (isUpperCase ? 'green' : null)}>Require at least one uppercase letter</span></li>
        <li> <span className={'validation-item ' + (isLowerCase ? 'green' : null)}>Require at least one lowercase letter</span></li>
        <li> <span className={'validation-item ' + (containsSymbols ? 'green' : null)}>Require at least one symbol character</span></li>
      </ul>
      <div className='validation-container'>
        <p className='progress-title'>Strength</p>
        <div className="progress">
          <div className="progress-bar" style={changePasswordColor()}></div>
        </div>
        <p className="progress-result" style={{ color: funcProgressColor() }}>{createPassLabel()}</p>
      </div>
    </React.Fragment >
  )
}

export default NewPasswordInput
