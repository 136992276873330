import { Auth, Hub } from 'aws-amplify';
import { store } from "../redux/store";
import { signinSuccess, signoutSuccess } from '../redux/actions/auth'

export const configureAuth = () => {
  const cognitoConfig = {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  }

  Auth.configure(cognitoConfig);

  Hub.listen('auth', data => {
    switch (data.payload.event) {
      case 'signIn':        
        store.dispatch(signinSuccess(data.payload.data))
        break
      case 'signOut':
        store.dispatch(signoutSuccess())
        break
      default:
        //console.log(data.payload.event)
    }
  });

  // Auth.currentAuthenticatedUser().then(data => {
  //   store.dispatch(signInSuccess(data))
  // });
}
