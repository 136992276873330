import React from "react";
import ReactDOM from "react-dom";
import { configureAuth } from './auth';
import { Analytics } from 'aws-amplify'
import App from "./App";
//import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-pivottable/pivottable.css";
import "./globalStyles.css";

Analytics.configure({ disabled: true })
configureAuth();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
