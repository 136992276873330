export const ACCIAL = "Accial";
export const ADMINISTRATOR = "Administrator";
export const COINVESTOR = "CoInvestor";
export const FACILITY = "Facility";
export const INVESTOR = "Investor";
export const PARTNER = "Partner";
export const PARTNERADMIN = "PartnerAdmin";
export const TRANSACTIONADMIN = "TransactionAdmin";
export const ORCAAASLPA = "ORCAaaSLPA";
export const ORCAAASDFA = "ORCAaaSDFA";
export const FUNDADMIN = "FundAdmin";