import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import routes from "./privateRoutes";

const NotFound = lazy(() => import("../views/NotFound/NotFound"));

function PrivateRouter({ url, userRoles, userName }) {  
  const authorizedRoutes = routes.filter(route => (route.roles && route.roles.some(r => userRoles.includes(r))) && ((!route.users || (route.users && route.users.some(u => u == userName)))))
  
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {authorizedRoutes.map(route => {
          const { component: Component } = route;
          return (
            <Route
              path={`${url}${route.path}`}
              key={route.path}
              exact={route.exact}
              render={props => <Component {...props} />}
            />
          );
        })}
        <Route render={props => <NotFound {...props} />} />
      </Switch>
    </Suspense>
  );
}

export default connect(
  state => ({ 
    userRoles: state.auth.roles,
    userName: state.auth.email    
  })
)(PrivateRouter);
