import { createReducer } from "redux-starter-kit";

const initialState = {
  isAuthenticated: false,
  accessToken: null,
  idToken: null,
  email: null,
  name: null,
  avatar: null,
  referenceId: null,
  roles: [],
  exp: null
};

const authReducer = createReducer(initialState, {
  SIGNIN_SUCCESS: (state, action) => ({
    isAuthenticated: true,
    accessToken: action.payload.signInUserSession.accessToken.jwtToken,
    idToken: action.payload.signInUserSession.idToken.jwtToken,
    email: action.payload.signInUserSession.idToken.payload.email,
    name: action.payload.signInUserSession.idToken.payload.name,
    roles: action.payload.signInUserSession.accessToken.payload['cognito:groups'],    
    exp: action.payload.signInUserSession.accessToken.payload.exp
  }),
  SIGNOUT_SUCCESS: (state, action) => initialState
});

export default authReducer;
